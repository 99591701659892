<template>
	<Modal :showModal="show" @close="$emit('close')">
		<template v-slot:header>
	      	<div class="modal-header">
		        <h3 class="modal-title text-white">Términos y condiciones</h3>
		        <button
		          type="button"
		          class="close text-white"
		          data-dismiss="modal"
		          aria-label="Close"
		        >
		          <span aria-hidden="true" @click="$emit('close')"
		            >×</span
		          >
		        </button>
	      	</div>
	    </template>
	    <template v-slot:body>
	      	<div class="terms-conditions">
		        <h3 class="font-weight-bold">1.- Las Partes:</h3>
		        <p class="mb5">
		          1.1.- FINTECA TECH, S.L. S.L. (<strong>“FINTECA”</strong> o
		          <strong>“ProHipotecas”</strong>), con NIF B-67.055.715 y domicilio en
		          Barcelona, c/ Plaça Pau Vila nº 1, 2 A planta, responsable del sitio
		          web accesible por medio de la URL www.prohipotecas.com o aplicación
		          móvil (en adelante, la <strong>"Plataforma"</strong>).
		        </p>
		        <p class="mb5">
		          1.2.- El USUARIO deberá ser una persona física, mayor de edad, agente
		          inmobiliario o asesor financiero con interés en ofrecer una hipoteca a
		          sus clientes, mediante la intermediación de PROHIPOTECAS y como
		          contraprestación a ese lead, recibir una comisión. FINTECA se reserva
		          el derecho de comprobar que el USUARIO reúne los requisitos necesarios
		          para prestarle los servicios de PROHIPOTECAS.
		        </p>
		        <h3 class="font-weight-bold">2.- Alcance del Servicio</h3>
		        <p class="mb5">
		          PROHIPOTECAS pone a disposición de los USUARIOS, en su condición de
		          potenciales partners estratégicos, una Plataforma para ayudar a
		          encontrar la mejor solución hipotecaria con entidades financieras para
		          sus clientes (el <strong>“Servicio”</strong>).
		        </p>
		        <p class="mb5">
		          El Servicio está sujeto a la aceptación por parte del USUARIO de la
		          totalidad de las condiciones contenidas en este documento (las
		          <strong>“Condiciones”</strong>), incluyendo lo dispuesto en la
		          Política de Privacidad. Al utilizar o acceder a la Plataforma, el
		          USUARIO acepta y conviene quedar vinculado por las presentes
		          Condiciones, así como por las condiciones que lo integran.
		        </p>
		        <h3 class="font-weight-bold">3.- Aceptación</h3>
		        <p class="mb5">
		          Con carácter previo a la adquisición de la condición de USUARIO, éste
		          deberá leer las presentes Condiciones y aceptarlas de forma expresa
		          haciendo clic en la casilla de verificación del consentimiento en la
		          Plataforma, que se encuentra desmarcada por defecto, al final del
		          proceso de registro correspondiente.
		        </p>
		        <p class="mb5">
		          Por tanto, al registrarse, el USUARIO acepta y acepta el Servicio de
		          PROHIPOTECAS con base a las presentes Condiciones.
		        </p>
		        <p class="mb5">
		          Asimismo, PROHIPOTECAS se reserva el derecho de modificar o reemplazar
		          cualquier estipulación de las presentes Condiciones en cualquier
		          momento, siendo notificados dichos cambios al USUARIO por correo
		          electrónico.&nbsp;Después de la notificación de cualquier cambio, se
		          entenderá aceptado salvo que el USUARIO cierre su cuenta, lo que
		          terminará el Servicio.
		        </p>
		        <h3 class="font-weight-bold">4.- Registro de cuenta</h3>
		        <p class="mb5">
		          Se solicitará al USUARIO que se registre para obtener una cuenta de
		          PROHIPOTECAS. Para dicho registro, será preciso indicar:
		        </p>
		        <ul class="mb5">
		          <li>Nombre y apellidos</li>
		          <li>DNI</li>
		          <li>Teléfono</li>
		          <li>Correo electrónico</li>
		          <li>contraseña</li>
		        </ul>
		        <p class="mb5">
		          Así mismo, PROHIPOTECAS podrá recabar de los USUARIOS la documentación
		          e información de sus clientes para prestar el Servicio. Al facilitar
		          cualquier tipo de información a PROHIPOTECAS (como por ejemplo durante
		          el proceso de registro), el USUARIO manifiesta y garantiza que está
		          plenamente capacitado y legitimado para hacerlo, y que dicha
		          información es completa, exacta y actualizada.&nbsp;El USUARIO se
		          obliga bajo su responsabilidad a aportar información de sus clientes
		          con su consentimiento.&nbsp;A este respecto PROHIPOTECAS se reserva el
		          derecho de solicitar que el USUARIO acredite dicho consentimiento.
		        </p>
		        <p class="mb5">
		          El USUARIO no compartirá su ID de usuario o contraseña de PROHIPOTECAS
		          con nadie, y deberá proteger la seguridad de su ID de usuario y
		          contraseña de PROHIPOTECAS como responsable de cualquier actividad
		          asociada con su cuenta.
		        </p>
		        <p class="mb5">
		          Nuestro Servicio permite al USUARIO cargar, transferir, almacenar,
		          enviar y recibir contenidos (los <strong>"Contenidos"</strong>). El
		          USUARIO conservará la titularidad de todos los derechos de propiedad
		          intelectual que posea sobre sus Contenidos, en el bien entendido de
		          que, al usar el Servicio, concede la autorización siguiente, con
		          cuantos derechos lleva aparejados:
		        </p>
		        <p class="mb5">
		          Al cargar, transferir, almacenar, enviar o recibir Contenidos a través
		          de PROHIPOTECAS, el USUARIO concede a esta última (y a las sociedades
		          colaboradoras de la misma) una autorización mundial para alojar,
		          almacenar, reproducir, modificar (dar nuevo formato o adaptar,
		          únicamente a efectos de que sus Contenidos funcionen mejor con su
		          Servicio), comunicar, publicar, exponer públicamente y distribuir
		          dichos Contenidos mientras dure el Servicio. Los derechos que esta
		          autorización comporta están estrictamente destinados al funcionamiento
		          y la mejora del Servicio, así como al desarrollo de nuevas
		          características o funcionalidades. Esta autorización se considerará
		          revocada en el momento en que el USUARIO cancele su cuenta.
		          Ofreceremos maneras de acceder y eliminar Contenidos de una cuenta
		          PROHIPOTECAS activa, pero no podemos garantizarle que siempre disponga
		          de esta posibilidad. Asegúrese de estar suficientemente capacitado
		          para concedernos la presente autorización para cualesquiera Contenidos
		          que transfiera a nuestro Servicio.
		        </p>
		        <p class="mb5">
		          Sin perjuicio de lo dispuesto a continuación, la autorización
		          precedente que el USUARIO confiere para sus Contenidos se considerará
		          revocada en un plazo de tiempo razonable en términos comerciales una
		          vez haya borrado o eliminado cualquiera de sus Contenidos del
		          Servicio.
		        </p>
		        <p class="mb5">
		          No obstante, el párrafo precedente, el USUARIO conviene que sus
		          Contenidos podrán ser almacenados (incluyendo a través de los
		          servicios de almacenamiento en la nube de terceros) con motivo de
		          nuestros procedimientos de copia de seguridad y que se eliminarán en
		          un plazo razonable de tiempo a partir de la cancelación de su cuenta.
		        </p>
		        <p class="mb5">
		          En caso de que nos transmita comentarios, sugerencias, propuestas de
		          mejora o perfeccionamiento y/o solicitudes de nuevas funciones con
		          respecto al Servicio (los <strong>"Comentarios"</strong>) directamente
		          a través de correo electrónico o de cualquier otro modo, el USUARIO
		          concede a PROHIPOTECAS una licencia mundial, no exclusiva,
		          transferible y con posibilidad para conceder sublicencias para usar,
		          reproducir, distribuir o de cualquier otro modo explotar íntegramente
		          dichos Comentarios a cualesquiera efectos.
		        </p>
		        <p class="mb5">
		          En relación con los Contenidos, el USUARIO asevera y garantiza que:
		          (i) tiene todos los derechos y/o licencias; (ii) los Contenidos no
		          violan ninguna normativa legal, y que (iii) los Contenidos no
		          infringen ni violan de cualquier otro modo los derechos de terceros
		          (incluyendo, a título enunciativo, no limitativo, los derechos de
		          propiedad intelectual, y los de protección de datos de carácter
		          personal y el derecho a la propia imagen). Asimismo, el USUARIO
		          afirma, declara y garantiza que puede y demostrará a PROHIPOTECAS
		          –previa solicitud de PROHIPOTECAS– todo lo garantizado en este
		          apartado.
		        </p>
		        <p class="mb5">
		          PROHIPOTECAS se reserva el derecho a decidir en todo momento y a su
		          entera discreción si sus Contenidos resultan inapropiados o
		          contravienen lo establecido en las presentes Condiciones. PROHIPOTECAS
		          no supervisa los Contenidos de manera regular, pero sí puede realizar
		          controles aleatorios, así como revisar Contenidos si se recibe una
		          denuncia. PROHIPOTECAS podrá eliminar cualquiera de los Contenidos en
		          todo momento. En caso de que algún Contenido sea denunciado o
		          eliminado será comunicado al USUARIO oportunamente.
		        </p>
		        <h3 class="font-weight-bold">
		          5.- Inicio de la gestión de PROHIPOTECAS
		        </h3>
		        <p class="mb5">El procedimiento será el siguiente:</p>
		        <ol class="mb5">
		          <li>
		            El USUARIO introduce sus datos personales: nombre y apellidos, DNI y
		            domicilio profesional, teléfono y correo electrónico.
		          </li>
		          <li>
		            El USUARIO acepta las Condiciones de Uso y Política de Privacidad.
		          </li>
		          <li>
		            PROHIPOTECAS en 24-48 horas se pone en contacto con el PARTNER y le
		            será activada la plataforma de financiación (backoffice) al objeto
		            de asesorarlo en la búsqueda de la mejor solución hipotecaria de sus
		            clientes.
		          </li>
		          <li>
		            El PARTNER tiene acceso a la Plataforma y acepta mediante enlace
		            cuál de las dos alternativas prefiere: (i) que PROHIPOTECAS gestione
		            todo con su cliente directamente devengándose una comisión a favor
		            del PARTNER del 20% por haber aportado sólo el lead o (ii) una
		            comisión del 30% si se ocupa de recabar la documentación y subirla a
		            la Plataforma para que PROHIPOTECAS sólo se encargue de buscar la
		            mejor solución hipotecaria para su cliente con base a dicha
		            información.
		          </li>
		          <li>
		            Si la opción escogida es (i), el PARTNER con el previo
		            consentimiento del cliente cede los datos de este a PROHIPOTECAS
		            para que puedan iniciar su servicio.
		          </li>
		          <li>
		            Si la opción escogida es (ii): el PARTNER registra la solicitud de
		            hipoteca de su cliente y sube toda la documentación necesaria
		            utilizando la Plataforma como backoffice.
		          </li>
		          <li>
		            PROHIPOTECAS procesa, examina y prepara el expediente e informa de
		            todos los datos y pasos para que el PARTNER pueda hacer el
		            seguimiento de todo el proceso.
		          </li>
		          <li>
		            Una vez formalizada la financiación con su cliente, el PARTNER será
		            notificado por correo electrónico del importe de la comisión
		            devengada según la opción indicada para que emita la factura
		            correspondiente a PROHIPOTECAS.
		          </li>
		        </ol>
		        <h3 class="font-weight-bold">6.- Restricciones</h3>
		        <p class="mb5">
		          El USUARIO se compromete a no realizar, ni permitir que terceros
		          realicen, ya sea directa o indirectamente, las siguientes acciones:
		          (i) aplicar ingeniería inversa, desensamblar, descompilar o de
		          cualquier otro modo tratar de descubrir el código fuente o los
		          algoritmos subyacentes de la totalidad o parte del Servicio; (ii)
		          modificar o crear derivados de cualquier parte del Servicio; (iii)
		          alquilar, arrendar o utilizar el Servicio para cualesquiera fines
		          comerciales (como por ejemplo cobrar a otros usuarios para interactuar
		          de cualquier modo con el Servicio); (iv) eliminar u ocultar
		          cualesquiera avisos sobre derechos de propiedad registrados presentes
		          en el Servicio; (v) utilizar el Servicio para fines ilícitos; (vi)
		          acceder a cualquier parte del Servicio de manera no explícitamente
		          prevista en el presente documento; (vii) enviar mensajes o correos
		          electrónicos no deseados (esto es, correo basura) a usuarios de
		          PROHIPOTECAS o, en todo caso, a través del Servicio; (viii) utilizar
		          nombres de dominio o URL de PROHIPOTECAS en su nombre de usuario sin
		          contar al efecto con nuestra previa autorización por escrito; (ix)
		          perturbar el Servicio o interferir con él de cualquier modo; o (x)
		          acceder al Servicio por medios automatizados, incluyendo, a título
		          meramente enunciativo y no limitativo, scripts, bots, arañas web,
		          rastreadores o scrapers.
		        </p>
		        <h3 class="font-weight-bold">7.- Protección de datos</h3>
		        <p class="mb5">
		          Los tratamientos de datos personales que realice PROHIPOTECAS en
		          relación con la prestación del Servicio, estarán sujetos a la
		          normativa española y europea, especialmente en el Reglamento 679/2016
		          General de Protección de Datos y normativa de desarrollo, y a aquella
		          normativa en materia de protección de datos de carácter personal
		          aplicable en cada momento.
		        </p>
		        <p class="mb5">
		          PROHIPOTECAS dispone de la capacidad necesaria para implantar y
		          aplicar las medidas técnicas y organizativas apropiadas sobre
		          protección de datos, de manera que los tratamientos de datos que
		          realicen sean conformes con los requisitos de dicha normativa.
		        </p>
		        <p class="mb5">
		          El USUARIO con respecto a sus datos personales podrá ejercitar los
		          derechos de acceso, rectificación, oposición, supresión, portabilidad
		          y limitación respecto del tratamiento dirigiéndose por escrito al
		          correo electrónico indicado en el encabezamiento de las presentes
		          Condiciones.
		        </p>
		        <p class="mb5">
		          PROHIPOTECAS recabará los datos personales y documentación del USUARIO
		          conforme a las mejores prácticas y a la legalidad vigente en cada
		          momento. En consecuencia, deberá obtener el consentimiento expreso del
		          USUARIO para la realización de los tratamientos de datos necesarios
		          para la prestación del Servicio, incluyendo la cesión de sus datos
		          personales a PROHIPOTECAS y a las entidades bancarias con las que ésta
		          colabore con el fin de prestar el Servicio. PROHIPOTECAS se obliga a
		          recabar y conservar el soporte duradero que suponga la evidencia del
		          consentimiento prestado por el USUARIO de los datos personales para la
		          cesión de estos a PROHIPOTECAS, adoptando para ello alguna de las
		          formas admitidas en derecho e informar al USUARIO de la política de
		          protección de datos y privacidad de PROHIPOTECAS, en caso de que el
		          tratamiento se efectúe por web.
		        </p>
		        <p class="mb5">
		          Por su parte PROHIPOTECAS se obliga a (i) tratar los datos personales
		          recibidos del USUARIO para el Servicio, de acuerdo con el
		          consentimiento prestado por el mismo y (ii) a ejecutar las
		          rectificaciones, supresiones, limitaciones de datos comunicadas por el
		          USUARIO.
		        </p>
		        <p class="mb5">
		          PROHIPOTECAS asume el cumplimiento de las obligaciones establecidas
		          por la normativa de protección de datos aplicable en cada momento y,
		          en particular, se compromete a aplicar las medidas de seguridad
		          técnicas y organizativas que garanticen la seguridad de sus
		          actividades de tratamiento, disponer del consentimiento específico y
		          expreso para el envío de comunicaciones comerciales, en su caso, y a
		          atender las peticiones de ejercicio de derechos que los usuarios
		          pudieran solicitar.
		        </p>
		        <h3 class="font-weight-bold">8.- Propiedad intelectual e industrial</h3>
		        <p class="mb5">
		          La utilización del Servicio no confiere al USUARIO la titularidad de
		          ningún derecho de propiedad intelectual sobre el citado Servicio o
		          cualesquiera contenidos de terceros a los que acceda. No estará
		          autorizado a utilizar contenidos de terceros existentes en nuestro
		          Servicio, salvo en la medida en que así lo permita la legislación
		          aplicable o en caso de obtener la previa autorización de su
		          propietario. Las presentes Condiciones no confieren derecho a utilizar
		          ninguna de las marcas ni los logotipos empleados en nuestro Servicio.
		          No se debe eliminar, ocultar ni modificar ninguno de los avisos
		          legales que se muestran en el propio Servicio o junto con este.
		        </p>
	      	</div>
	    </template>
	</Modal>
</template>

<script>
	import { defineAsyncComponent } from 'vue';

	export default {
		name: 'TermsAndConditionsModal',
	    props: {
		    show: {
		      type: Boolean,
		      required: true,
		    }
		},
	    components: {
		    Modal: defineAsyncComponent(() => import('@/components/Modal.vue'))
		},
	};
</script>